import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { light, dark } from 'themes';
import { capitalCase } from 'change-case';
import Img from 'components/Img';
import breakpoints from 'utils/breakpoints';
import Layout from 'components/Layout';
import Heading from 'components/Heading';
import Paragraph from 'components/Paragraph';
import SameCategoryPost from 'components/BlogDetail/SameCategory';

const Hero = styled.section`
  background: linear-gradient(180deg, #ddeeff 0%, #ffffff 100%);
  display: flex;
  padding-top: 80px;
  padding-bottom: 20px;
  align-items: center;
  text-align: center;

  @media screen and (min-width: ${breakpoints.md}) {
    height: 350px;
  }
`;

const Note = styled(Paragraph)`
  strong {
    display: inline-block;
    padding: 0 5px;
  }
`;

const Content = styled.section`
  font-size: 16px;
  line-height: 28px;
  padding-bottom: 50px;
  p {
    margin: 20px 0;
  }
  a {
    color: #0276f1;
    text-decoration: none;
  }
  border-bottom: 1px solid #dadada;
`;

const FeaturedImage = styled.figure`
  height: 527px;
  margin: 0;
  width: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
`;

const OtherPosts = styled.section`
  padding: 25px 0 150px;
  h3 {
    text-align: center;
  }
`;

const StyledImg = styled(Img)`
  height: 100%;
  width: auto;
  max-width: 100%;
  display: block;
  margin: 0 auto;
`;

function BlogDetail({ data }) {
  const { currentPost, sameCategoryPosts, otherPosts } = data;
  const { frontmatter, html, fields } = currentPost;
  const { category } = fields;
  const { title, author, time, featuredImage } = frontmatter;
  const rawSameCategoryPosts = sameCategoryPosts.nodes;
  const rawOtherPosts = otherPosts.nodes;
  const morePosts = rawSameCategoryPosts.length
    ? rawSameCategoryPosts
    : rawOtherPosts;
  const morePostsTitle =
    rawSameCategoryPosts.length > 0
      ? `More from ${capitalCase(category.label)}`
      : 'Other posts';
  const renderOtherPost = rawPost => (
    <Col lg={6} key={rawPost.id}>
      <SameCategoryPost
        title={rawPost.frontmatter.title}
        slug={rawPost.fields.slug}
        featuredImage={rawPost.frontmatter.featuredImage}
        categoryName={
          rawPost.fields.category ? rawPost.fields.category.label : null
        }
      />
    </Col>
  );

  return (
    <ThemeProvider theme={light}>
      <Layout fullSize overflow pageTitle={title}>
        <ThemeProvider theme={dark}>
          <Hero>
            <Container>
              <Row justify="center">
                <Col md={10} lg={8}>
                  <Heading title={title} />
                  <Note>
                    Posted by
                    <strong>{author}</strong>
                    in
                    <strong>{capitalCase(category.label)}</strong>
                    at
                    <strong>{new Date(time).toLocaleDateString()}</strong>
                  </Note>
                </Col>
              </Row>
            </Container>
          </Hero>
          <Container>
            <FeaturedImage>
              <StyledImg {...featuredImage} />
            </FeaturedImage>
            <Row justify="center">
              <Col md={8}>
                <Content dangerouslySetInnerHTML={{ __html: html }} />
              </Col>
            </Row>
            <Row justify="center">
              <Col md={10}>
                <OtherPosts>
                  <Heading title={morePostsTitle} />
                  <Row align="center">{morePosts.map(renderOtherPost)}</Row>
                </OtherPosts>
              </Col>
            </Row>
          </Container>
        </ThemeProvider>
      </Layout>
    </ThemeProvider>
  );
}

const frontmatterProps = PropTypes.shape({
  title: PropTypes.string,
  author: PropTypes.string,
  time: PropTypes.string,
  featuredImage: PropTypes.shape({}),
});

const fieldsProps = PropTypes.shape({
  category: PropTypes.shape({
    label: PropTypes.string,
  }),
  slug: PropTypes.string,
});

const otherPostNodeProps = PropTypes.shape({
  fields: fieldsProps,
  frontmatter: frontmatterProps,
});

const currentPostProps = PropTypes.shape({
  frontmatter: frontmatterProps,
  fields: fieldsProps,
});

BlogDetail.propTypes = {
  data: PropTypes.shape({
    currentPost: currentPostProps,
    sameCategoryPosts: PropTypes.shape({
      nodes: PropTypes.arrayOf(otherPostNodeProps),
    }),
    otherPosts: PropTypes.shape({
      nodes: PropTypes.arrayOf(otherPostNodeProps),
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query($slug: String!, $categoryKey: String!) {
    currentPost: markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        category {
          label
          key
        }
        slug
      }
      frontmatter {
        time
        title
        author
        featuredImage {
          childImageSharp {
            fixed(height: 527) {
              ...GatsbyImageSharpFixed
            }
          }
          publicURL
        }
      }
      html
    }
    sameCategoryPosts: allMarkdownRemark(
      filter: {
        fields: { category: { key: { eq: $categoryKey } }, slug: { ne: $slug } }
      }
    ) {
      nodes {
        id
        frontmatter {
          title
          featuredImage {
            publicURL
            childImageSharp {
              fluid(maxWidth: 320) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fields {
          category {
            label
            key
          }
          slug
        }
      }
    }
    otherPosts: allMarkdownRemark(
      filter: {
        fields: { slug: { ne: $slug } }
        fileAbsolutePath: { glob: "**/src/blogs/*" }
      }
    ) {
      nodes {
        id
        frontmatter {
          title
          featuredImage {
            publicURL
            childImageSharp {
              fluid(maxWidth: 320) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fields {
          category {
            label
            key
          }
          slug
        }
      }
    }
  }
`;

export default BlogDetail;
