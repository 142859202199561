import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PureImg from 'components/Img';
import breakpoints from 'utils/breakpoints';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Wrapper = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 3px;
  background-color: #f7f7f7;

  @media screen and (min-width: ${breakpoints.md}) {
    display: flex;
  }
`;

const ImgWrap = styled.div`
  margin-bottom: 20px;
  @media screen and (min-width: ${breakpoints.md}) {
    margin-bottom: 0;
  }
`;

const Img = styled(PureImg)`
  width: 100%;
  @media screen and (min-width: ${breakpoints.md}) {
    width: auto;
    height: 153px;
  }
`;

const CategoryName = styled.span`
  opacity: 0.5;
  color: #1c2f45;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-decoration: none;
  margin-bottom: 10px;
  display: block;
`;

const Content = styled.div`
  @media screen and (min-width: ${breakpoints.md}) {
    padding-left: 20px;
  }
`;

const Title = styled.h4`
  text-decoration: none;
  color: #1c2f45;
  margin: 0;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
`;

function OtherPost({ className, categoryName, featuredImage, title, slug }) {
  return (
    <StyledLink to={`/blog/${slug}`} title={title}>
      <Wrapper className={className}>
        <ImgWrap>
          <Img {...featuredImage} />
        </ImgWrap>
        <Content>
          <CategoryName>{categoryName}</CategoryName>
          <Title>{title}</Title>
        </Content>
      </Wrapper>
    </StyledLink>
  );
}

OtherPost.defaultProps = {
  className: null,
};

OtherPost.propTypes = {
  className: PropTypes.string,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  featuredImage: PropTypes.shape({}).isRequired,
};

export default OtherPost;
